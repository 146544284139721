/* eslint-disable sonarjs/no-duplicate-string */
import { SkillItemInterface as SkillItemInterfacePDF } from '@component/Pdf/Skills';
import { EducationInterface } from '@component/Section/About.type';
import { ExperienceItemInterface } from '@component/Section/Experience.type';
import { HeaderInterface, SocialMediaItemInterface } from '@component/Section/Header.type';
import { SkillItemInterface } from '@component/Section/Skills.type';

const sensitiveData: {
  email: string;
  phoneNumber: string;
} = {
  email: process.env.EMAIL,
  phoneNumber: process.env.PHONE_NUMBER
};

export interface ContactInterface {
  phoneNumber: string;
  email: string;
  website?: string;
  linkedIn: string;
}
const contactData: ContactInterface = {
  email: sensitiveData.email,
  website: 'https://metasora.com',
  phoneNumber: sensitiveData.phoneNumber,
  linkedIn: 'https://linkedin.com/in/geuntabuwono'
};

export const additionalInfo: {
  latestUpdate: Date;
} = {
  latestUpdate: new Date()
};

const socialMediaData: Array<SocialMediaItemInterface> = [
  {
    iconName: 'github',
    url: 'https://github.com/GeuntaBuwono'
  },
  {
    iconName: 'twitter',
    url: 'https://twitter.com/BangGent'
  },
  {
    iconName: 'linkedin',
    url: contactData.linkedIn
  },
  {
    iconName: 'link',
    url: 'https://metasora.com'
  }
];

const headerData: HeaderInterface = {
  avatarUri: '/images/profile.jpeg',
  name: 'Geunta Buwono',
  title: 'Front End Principal Engineer',
  domicile: 'Jakarta, Indonesia',
  socialMedia: socialMediaData
};

const educationData: EducationInterface = {
  degree: 'bachelor',
  graduated: new Date('2012-01-01'),
  major: 'Information System',
  university: 'Bina Nusantara (Binus)'
};

const skillData: Array<SkillItemInterface> = [
  {
    title: 'Front-End',
    list: [
      'Javascript',
      'Typescript',
      'NodeJS',
      'React',
      'React Native',
      'Verdaccio',
      'GraphQL',
      'Apollo',
      'Storybook',
      'Styled Component',
      'Hasura'
    ]
  },
  {
    title: 'Back-End',
    list: ['Javascript', 'NodeJS', 'express', 'GraphQL']
  },
  {
    title: 'Server',
    list: ['Amazon Web Service (AWS)', 'EBS, EC2, Amplify', 'Docker', 'PM2', 'AppCenter', 'nginx']
  },
  {
    title: 'SaaS',
    list: ['Google Play Console', 'Apple Developer Console', 'Firebase', 'OneSignal', 'Sentry']
  },
  {
    title: 'Project Management',
    list: ['Git', 'BitBucket', 'Jira Atlassian', 'GitHub', 'GitLab', 'Asana']
  }
];

const Freelance: ExperienceItemInterface = {
  onClick: () => undefined,
  from: new Date('2022-06-01'),
  company: 'Freelance',
  jobTitle: 'Freelance Frontend Engineer',
  details: ['Build Frontend Product', 'Scaffolding Project', 'Refactoring Project']
};

const experiencesData: Array<ExperienceItemInterface> = [
  {
    onClick: () => undefined,
    from: new Date('12-12-2022'),
    company: 'VirtualSpirit',
    jobTitle: 'Lead Front End Engineer',
    isHideFromPublic: true,
    managedPeople: 4,
    details: [
      'Mentoring and Leading Frontend Team',
      'FrontEnd Candidate Interviewer',
      'Create an FrontEnd Refactor Plan',
      'Create Principal Rules for Codebase',
      'Create API contract with Backend Engineer Team',
      'Create Design contact with Designer Team',
      'Create CI/CD for FrontEnd Stack',
      'Knowledge sharing between FE and QA team with Cypress for automation',
      'Work closely with Project Manager (Executive Level) and CTO'
    ]
  },
  {
    onClick: () => undefined,
    from: new Date('03-01-2023'),
    to: new Date('01-01-2024'),
    company: 'KERB',
    jobTitle: 'Front End Engineer',
    isHideFromPublic: true,
    details: [
      'Build Feature',
      'Handle payment system with stripe on frontend side',
      'Research and Development Stripe Terminal',
      'Work closely with Engineering Manager'
    ]
  },
  Freelance,
  {
    onClick: () => undefined,
    from: new Date('2021-03-01'),
    to: new Date('2022-06-01'),
    company: 'GajiCermat',
    jobTitle: 'Front End Principal Engineer',
    managedPeople: 8,
    details: [
      'Mentoring and Leading Frontend Team',
      'FrontEnd Candidate Interviewer',
      'FrontEnd Tech Stack Decisor',
      'Create an FrontEnd Architecture',
      'Create Principal Rules for Codebase',
      'Create API contract with Backend Engineer Team',
      'Create Design contact with Designer Team',
      'Create CI/CD for FrontEnd Stack',
      'Create and Maintain Cross Platform Shared Component',
      'Create and Maintain Cross Private Package Registry with Verdaccio',
      'Recommend New Technologies, Flow and Rules',
      'Work closely with VP Engineering and CTO',
      'Last Gate for Pull Request'
    ]
  },
  {
    onClick: () => undefined,
    from: new Date('2020-08-01'),
    to: new Date('2021-03-01'),
    company: 'Yummycorp',
    jobTitle: 'Fullstack Engineer (Frontend)',
    managedPeople: 5,
    details: [
      'Mentoring and Leading Frontend Team',
      'Mentoring Product to use product tools (Analytic)',
      'FrontEnd Tech Stack Decisor',
      'Create an FrontEnd Architecture',
      'Create Principal Rules for Codebase',
      'Create API contract with Backend Engineer Team',
      'Create Design contact with Designer Team',
      'Create CI/CD for React Native',
      'Create CI/CD for NextJS WebApp',
      'Work closely with Team Lead and Head of Engineering',
      'Last Gate for Pull Request'
    ]
  },
  Freelance,
  {
    onClick: () => undefined,
    from: new Date('2017-09-01'),
    to: new Date('2019-10-01'),
    company: 'kumparan',
    jobTitle: 'Frontend Engineer',
    details: [
      'Engineer Buddy',
      'FrontEnd Candidate Interviewer',
      'In Charge Ads Management',
      'In Charge Tracking System',
      'Code Reviewer',
      'Research Tools for Feature',
      'Rewrite Codebase',
      'Maintain Internal Dashboard',
      'Maintain Web App',
      'Maintain Cross Platform Shared Component'
    ]
  },
  {
    onClick: () => undefined,
    from: new Date('2017-01-01'),
    to: new Date('2017-09-01'),
    company: 'Gojek',
    jobTitle: 'Frontend Developer',
    details: ['Mentoring', 'Rewrite Codebase', 'Maintain Website']
  },
  {
    onClick: () => undefined,
    from: new Date('2016-09-01'),
    to: new Date('2016-12-01'),
    company: 'Sofco Graha Konimex Group',
    jobTitle: 'Frontend Engineer',
    details: ['Mentoring', 'Research Angular 2 RC', 'Research Cross Platform Mobile App']
  }
];

const projectData: Array<ProjectInterface> = [
  {
    name: 'Internal Dashboard and Mobile Application (Android)',
    status: '(Already take down from PlayStore)',
    link: 'https://play.google.com/store/apps/details?id=com.isyanaprod',
    jobTitle: 'Project Lead and Software Engineer',
    from: new Date('2019-11-01'),
    to: new Date('2020-03-01'),
    managedPeople: 2,
    details: [
      'Manage Frontend and Designer',
      'Collaborate with stackholder',
      'Serve GraphQL Server',
      'Make an REST API with NodeJS and integrate with Bank BNI API',
      'Make Android Application with React Native',
      'Slicing design from designer',
      'Dockerised Backend Application',
      'Setup Application to bare metal server'
    ]
  },
  {
    name: 'Akurat.co (Mobile Application Android and iOS)',
    link: 'https://play.google.com/store/apps/details?id=com.app.akurat',
    jobTitle: 'Frontend Engineer Mobile',
    status: '(Already take down from Apple Store)',
    from: new Date('2019-03-01'),
    to: new Date('2019-04-01')
  },
  {
    name: 'Akurat.co (Website and Internal Dashboard)',
    link: 'https://akurat.co',
    jobTitle: 'Project Lead and Frontend Engineer Web',
    managedPeople: 2,
    from: new Date('2018-11-01'),
    to: new Date('2019-03-01'),
    details: [
      'Integrate with Data from laravel',
      'Adding some animation on web',
      'Slicing website from design',
      'Integrate API (Youtube and Google Map)'
    ]
  }
];
export interface ResumeInterface {
  header: HeaderInterface;
  education: EducationInterface;
  skills: Array<SkillItemInterfacePDF>;
  experiences: Array<ExperienceItemInterface>;
  project: Array<ProjectInterface>;
  contact: ContactInterface;
}

export const resumeData: ResumeInterface = {
  header: headerData,
  education: educationData,
  skills: skillData,
  experiences: experiencesData,
  project: projectData,
  contact: contactData
};

export const portfolioData = {
  educationData,
  skillData,
  headerData,
  roadMap: experiencesData
};
